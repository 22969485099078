import './App.css';
import { LegalDoc } from './legaldoc';

import React from 'react';
import './App.css';
import privacysite from './legalfiles/privacysite.json';
import contact from './legalfiles/contact.json';

import {
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";

export default function App() {
  return (
    <div>
      <ImgQZ />
    </div>
  );
}

class Layout extends React.Component {
  render() {
    return <div>
    <Link to="/"><img src="imgqz.png" width="256" height="256" alt="ImgQZ logo" class="logo" /></Link>
    <Outlet />
    <Footer switchLanguage={this.props.switchLanguage} userLang={this.props.userLang} />
  </div>;
  }
}

class ImgQZ extends React.Component {
  constructor(props) {
    super(props);
    console.log(navigator.language);
    console.log(navigator.userLanguage);
    let userLang = "en";
    if ((/^de\b/.test(navigator.language)) || (/^de\b/.test(navigator.userLanguage))) {
      userLang = "de";
    }
    this.state = { userLang: userLang };
    this.switchLanguage = () => {
      this.setState((state) => {
        // Important: read `state` instead of `this.state` when updating.
        return { userLang: state.userLang === 'de' ? 'en' : 'de' }
      });
    }
  }
  render() {
    return <Routes>
      <Route path="/" element={<Layout switchLanguage={this.switchLanguage} userLang={this.state.userLang}/>}>
        <Route index element={<Home userLang={this.state.userLang} />} />
        <Route path="/privacypolicy" element={<LegalDoc content={privacysite.content[this.state.userLang]} title={privacysite.title[this.state.userLang]} />} />
        <Route path="/contact" element={<LegalDoc content={contact.content[this.state.userLang]} title={contact.title[this.state.userLang]} />} />
      </Route>
    </Routes>;
  }
}

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.homeTitle = {
      'en': 'ImgQZ - Your social quiz :-)',
      'de': 'ImgQZ - Das vernetzte Quiz :-)'
    }
    this.homeText = {
      'en': 'News coming soon!',
      'de': 'Bald gibt es Neuigkeiten'
    }
  }
  render() {
    return <main>
      <div class="content">
        <h1>{this.homeTitle[this.props.userLang]}</h1>
        <div dangerouslySetInnerHTML={{ __html: this.homeText[this.props.userLang] }} />
      </div></main>;
  }
}

class Footer extends React.Component {
  render() {
    return <main id="footer" class="center">
      <Link to="/contact">{contact.title[this.props.userLang]}</Link>&nbsp; | &nbsp;<Link to="/privacypolicy">{privacysite.title[this.props.userLang]}</Link>  &nbsp; | &nbsp; {"->"} <Link onClick={this.props.switchLanguage}>{this.props.userLang === 'de' ? 'en' : 'de'}</Link>
    </main>;
  }
}